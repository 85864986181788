import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class DreamPayStore extends BaseStore {
  @action
  getFanId(email) {
    return request({
      method: 'GET',
      path: `/1/tappit/fan-id/${email}`,
    }).then((data) => {
      return data.fanId;
    });
  }

  @action
  prespend(body) {
    return request({
      method: 'POST',
      path: `/1/tappit/prespend`,
      body,
    }).then((data) => {
      return data;
    });
  }

  @action
  spend(body) {
    return request({
      method: 'POST',
      path: `/1/tappit/spend`,
      body,
    }).then((data) => {
      return data;
    });
  }
}
