import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class ExternalObjectsStore extends BaseStore {
  @action
  search(params) {
    return new Promise((resolve, reject) => {
      window.analytics.ready(() => {
        request({
          method: 'POST',
          path: `/1/externalobjects/search`,
          body: {
            ...params,
            anonymousId: window.analytics.user().anonymousId(),
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }
}
