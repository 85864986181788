import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCreative, Autoplay } from 'swiper';

import { useClassName } from 'common/hooks';

import HomepageButton from '../../Components/Button';
import { useNext, usePrev } from '../../Components/useNavigation';
import { usePagination } from '../../Components/usePagination';
import HomepageAsset from '../../Components/Asset';
import trackHomepageSlide from '../../Components/trackHomepageSlide';

import 'swiper/swiper.less';
import 'swiper/modules/effect-creative/effect-creative.less';
import 'swiper/modules/autoplay/autoplay.less';
import './hero-section.less';

function playVideo(swiper) {
  const slide = swiper.slides[swiper.activeIndex];
  if (!slide) return;

  const video = slide.getElementsByTagName('video')[0];
  if (video) {
    video.play();
  }
}

function pauseVideo(swiper) {
  const slide = swiper.slides[swiper.activeIndex - 1];
  if (!slide) return;

  const video = slide.getElementsByTagName('video')[0];
  if (video) {
    video.pause();
  }
}

function filterAudience(section) {
  return section.fields.media
    .filter((media) => {
      const { audienceControls } = media.fields;
      if (!audienceControls?.length) return true;

      return audienceControls.every((control) => {
        const { fields } = control;

        if (fields.type === 'Hide' && fields.result) return false;
        if (fields.type === 'Show' && !fields.result) return false;

        return true;
      });
    })
    .sort((media) => {
      const { audienceControls } = media.fields;
      if (!audienceControls?.length) return 1;

      return audienceControls.some(
        ({ fields }) => fields.type === 'Feature' && fields.result
      )
        ? -1
        : 1;
    });
}

export default function HeroSection({ section }) {
  const classNames = useClassName('HeroSection');
  const [navigationPrevRef, prev] = usePrev({
    className: classNames('prev'),
  });
  const [navigationNextRef, next] = useNext({
    className: classNames('next'),
  });
  const pagination = usePagination({
    horizontalClass: classNames('bullets-container'),
  });
  const media = filterAudience(section);
  console.log('media: ', media);

  return (
    <div style={{ marginBottom: '84px' }} id={section.fields.sectionId}>
      <Swiper
        modules={[Navigation, Pagination, EffectCreative, Autoplay]}
        height={542}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: section.fields.autoplayDuration }}
        creativeEffect={{
          prev: {
            translate: ['-100%', 0, 0],
          },
          next: {
            translate: ['100%', 0, 0],
          },
          scale: 0.3,
        }}
        effect="creative"
        loop={true}
        className={classNames('wrapper')}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        onSlideChange={(swiper) => {
          pauseVideo(swiper);
          playVideo(swiper);
          trackHomepageSlide(() => ({
            activeIndex: swiper.realIndex,
            name: media[swiper.realIndex].fields.name,
            section: section.fields.sectionId,
            url: media[swiper.realIndex].fields.callToAction.fields.url,
          }));
        }}
        {...pagination}>
        {media.map(({ fields }) => (
          <SwiperSlide
            key={fields.desktop.fields.file.url}
            className={classNames('slide-container')}>
            <div className={classNames('slide')}>
              {fields.isVideo ? (
                <>
                  <HomepageAsset asset={fields.asset} poster={fields.poster} />
                  <div className={classNames('content')}>
                    {fields.title && <h1>{fields.title}</h1>}
                    {fields.description && <h2>{fields.description}</h2>}
                    {fields.callToAction &&
                      !fields.callToAction.fields.isHidden && (
                        <HomepageButton
                          cta={fields.callToAction}
                          className={classNames('cta')}
                        />
                      )}
                  </div>
                </>
              ) : (
                <HomepageButton cta={fields.callToAction}>
                  <HomepageAsset asset={fields.asset} />
                </HomepageButton>
              )}
            </div>
          </SwiperSlide>
        ))}
        {prev}
        {next}
      </Swiper>
    </div>
  );
}
